.nav__links {
  display: none;
  font-size: 15px;

  @media (--gte-tablet) {
    display: flex;
    align-items: baseline;
  }

  .intercom {
    margin-right: 0;

    i {
      margin-right: 0;
    }
  }
}

.nav__link {
  color: var(--purple6);
  font-weight: 300;
  margin: 10px 4px;
  padding: 6px;
  display: inline-block;
  position: relative;

  @media (--gt-tablet) {
    margin: 10px 8px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--green2);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  &.active,
  &:hover {
    color: var(--primary-color);

    &::before {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  &.active {
    font-weight: 300;
  }

  .badge {
    background-color: var(--purple6);
    border-radius: 8px;
    width: 16px;
    height: 16px;
    color: white;
    position: absolute;
    top: calc(50% - 8px);
    right: -14px;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    &.red {
      background-color: red;
    }
  }

  > i {
    margin-right: 0;
  }
}

.nav__link_active {
  font-weight: 500;
  color: var(--black);

  &::after {
    content: '';
    display: block;
    width: 60%;
    margin: 6px auto 0;
  }

  &:hover::before {
    visibility: hidden;
  }
}

.nav__link_login {
  @mixin button-transparent;
  margin-left: 10px;
  padding: 2px 8px;
  font-size: 14px;
  border-color: var(--gray5);
  color: var(--purple6);
  font-weight: 300;

  &:hover {
    border-color: var(--primary-color);
  }
}

.nav__link_icon {
  @mixin button-icon;
  color: var(--black);
  top: 3px;
  position: relative;
  width: 32px;
  height: 32px;

  &:hover {
    color: var(--primary-color);
  }

  i {
    --icon-size: 1.1em;
  }
}
