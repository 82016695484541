@import 'anchor.css';
@import 'definition.css';
@import 'footer.css';
@import 'main.css';
@import 'page_loader.css';
@import 'deprecated/index.css';

#floating-ui-root,
[data-floating-ui-portal] {
  z-index: 200;
}

.tippy-box {
  font-size: 12px !important;
}
