@import 'logo';
@import 'links';

nav {
  background-color: var(--white);
  box-shadow: var(--block-shadow-min);
  z-index: 99;
  position: sticky;
  width: 100%;
  top: 0;

  .container {
    display: flex;
    width: 96%;
    justify-content: space-between;
    align-items: center;

    @media (--gte-tablet) {
      width: 98%;
    }
  }
}
