.nav__logo {
  padding: 10px 0 0 3px;
  display: block;
  opacity: 1;
  transition: opacity 300ms;
  height: var(--body-margin-top);

  @media (--gte-tablet) {
    padding: 15px 0 0 3px;
  }

  @media (--gte-desktop) {
    margin-top: 0;
  }

  img {
    height: 36px;
    width: 131px;

    @media (--gte-tablet) {
      height: 42px;
      width: 152px;
    }

    @media (--gte-desktop) {
      height: 50px;
      width: 182px;
    }
  }

  a {
    display: block;
    text-align: left;
  }
}
